export const timeBlockTemplate = {
  // daypilot required properties
  start: undefined, // can be replaced with duration for unscheduled events
  end: undefined, // can be replaced with duration for unscheduled events
  id: undefined, // checklist id + time block idx, must be unique
  resource: undefined,
  text: undefined, // the text that is displayed on the event bar (can be overridden by html property below)
  // daypilot optional properties
  html: undefined, // if set, html is displayed instead of plain text on the event bar - we use this to give event dom elements an id and set search string matches in bold
  moveDisabled: undefined, // true for blocks of checklists with a hold, and geparkt blocks, else undefined to ensure dynamic behaviour based on scheduler.eventMoveHandling
  moveHDisabled: undefined, // true for blocks that may only be moved vertically, not horizontally (i.e., if in production)
  // moveVDisabled: undefined, // true for blocks that may not be moved vertically, i.e. in production with a hold // commented because redundant with moveDisabled
  barColor: undefined, // color of the status bar on top of event, set identically for all checklist bars
  usualBarColor: undefined, // used to cache the original top bar color while the event is conflicting
  cssClass: undefined, // used to set the bar back color: slightly darker gray for SIT bars that belong to an SGR system, else standard grey
  usualCssClass: undefined, // used to cache the original css class while that is currently set to search string match
  bubbleHtml: undefined, // the html to be displayed as tooltip on hover
  areas: undefined, // the custom interaction/info elements that are displayed on the event bar, depend on edit mode and bar type
  // custom properties
  correspondingSystemId: undefined, // to match the system back with the time blocks whenever needed
  correspondingChecklistId: undefined, // to match the time blocks back with their checklist if needed
  checklistType: undefined, // should be "SIT", "SGR" or "Geparkt"
  indexWithinChecklist: undefined, // increments for all time blocks within checklist, i.e. 0-2 for each checklist
  isScheduled: undefined, // for quick access about whether a time block/it's corresponding checklist are scheduled
  checklistIsStarted: undefined, // for quick access of which actions will be allowed or forbidden related to this
  behaviourOverAbsences: undefined, // "Expand" for the last time block of checklist, "None" for all others
  lastPossibleCompletionDate: undefined, // DayPilot.Date of the hard completion deadline for the system 
  conflicts: new Set(), // array of all conflicting time block ids
  idOfThisHold: undefined, // DB id of the hold object from which this hold was derived
  assignedEmployee: undefined, // employee that is assigned to the checklist, set identically for all checklist bars
  systemStatus: undefined,
  hide: false, // true if this time block should not be shown on factoryboard
  permitSchedulingOverlap: undefined, // true if this timeblock should permit overlapping scheduling with subsequent block
};
